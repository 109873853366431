<template>
<div class="tabulation"  v-loading="loading" element-loading-text="加载中" element-loading-background="rgb(255, 255, 255, 0.5)">
  <el-card class="chart-card">
    <div style="margin-top: 20px;">
      <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          class="demo-ruleForm"
          :size="formSize"
          status-icon
       >
      <!-- 标题 -->
        <el-form-item label="标题名称：" prop="name">
          <div>{{form.name}}</div>
        </el-form-item>
      <!-- 标题图片 -->
        <el-form-item  label="主题图片：" prop="breviary">
          <el-upload action="/admin/child/saveEditorImage" list-type="picture-card" limit="1" 
            v-model="form.breviary"
            v-show="imgss==''||imgss==null?false:true"
            :auto-upload="true" 
            :on-change="change" 
            :on-remove="Remove"
            :class="styA==0&&styD==0?'styB':'styC'"
            :before-upload="beforeImageUpload"
            :before-remove="beforeremove"
            :on-preview="onpreview"
            :headers="header"
            :file-list="approve"
            accept=".jpeg,.png,.jpg,.bmp,.gif"
            disabled="true"
            :data='{
              imageFile: file
              }'
            name="imageFile"
            :on-success="succ"
            >
           <el-icon><Plus /></el-icon>
          </el-upload>
          <div v-show="imgss==''||imgss==null?true:false">无</div>

          <el-dialog v-model="dialogVisible">
            <img w-full :src="dialogImageUrl" alt="Preview Image" style="width:100%"/>
          </el-dialog>
          <!-- <div class="referrals">
            ( 推荐：图片尺寸 宽700px 高280px )
          </div> -->
        </el-form-item>
        <!-- 类型 -->
       <el-form-item label="活动类型：" prop="classify">
          <div>{{form.classify}}</div>
        </el-form-item>
      <!-- 是否发布 -->
       <el-form-item label="是否发布：" prop="are">
          <!-- <el-select v-model="form.are" placeholder="请选择是否发布" size="medium">
            <el-option label="是" value="1"  style="width:100%; text-align-last: center;" ></el-option>
            <el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
          </el-select> -->
          <div>{{form.are}}</div>
        </el-form-item>
      <!-- 编辑器 -->
      <div class="bianji">
          <div class="subject">
            <span>*</span>
            主体内容 :
          </div>
          <div v-html="valueHtml" class="cat"></div>
        </div>
    </el-form>
    </div>
  </el-card>
</div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted,onActivated, reactive } from 'vue'
import {  Plus,} from '@element-plus/icons-vue'
import * as imageConversion from 'image-conversion'
import { ElMessage } from "element-plus";
import { FCredact } from "../../utils/api"
// import { Pictures } from '../../utils/api'
import qs from 'qs'
export default{
  name:"FCdetails",
  components:{  Plus, },
  setup(){
    // 加载框
    let loading=ref(true)
     // 图片显示 隐藏
    let imgss=ref('')
    // 默认显示已上传文件
    let approve =ref([])
    // 子女托管编辑获取信息
    let dact =()=>{
      let data={
        id:sessionStorage.getItem('FCHD')
      }
      FCredact(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          loading.value=false
          imgss.value=res.data.data.previousDataInfo.imageInfo
          valueHtml.value=res.data.data.previousDataInfo.content
          form.name=res.data.data.previousDataInfo.title
          // 类型
          if(res.data.data.previousDataInfo.type==1){
            form.classify='直属机关工会'
          }else if(res.data.data.previousDataInfo.type==2){
            form.classify='基层工会'
          }
          // 是否发布
          if(res.data.data.previousDataInfo.isEnable==2){
            form.are='否'
          }else if(res.data.data.previousDataInfo.isEnable==1){
            form.are='是'
          }
          if(res.data.data.previousDataInfo.imageInfo==null||res.data.data.previousDataInfo.imageInfo==''){
            styD.value=0
          }else{
            styD.value=1
            let data={
              url:res.data.data.previousDataInfo.imageInfo
            }
            approve.value.push(data)
          }
        }
      })
    }
    let data = ref(null);
    // 按钮加载
    const ConfirmLoading=ref(false)
    
    // 上传图片
    const header=ref({
      timeout: 10000,
      'accessToken':sessionStorage.getItem('token')
    })
    // 获取上传图片成功地址
    let location= ref('')
    const succ=(res)=>{
      location.value=res.data.url.substring(57)
      console.log(res.data.url.substring(57))

    }
    // 自定义上传
    const httpreqst=(param)=>{
      console.log(param)
      console.log(param.file.name)
      data.value ={
        imageFile:param.file.namefileList
      }
      // Pictures(data).then((res)=>{
      //   console.log(res)
      // })
    }
    const styA = ref(0)
    const styD=ref(1)
    
    const change=(res,fileList)=>{
      // console.log(res)
      styA.value=fileList.length
      form.breviary=fileList
      // console.log(fileList)
    }
    const beforeImageUpload=(File)=>{
      let isLt2M = File.size / 1024 / 1024 < 1 
      return new Promise((resolve)=>{
        if (isLt2M) {
          resolve(File)
        }
        imageConversion.compressAccurately(File, 930).then((res) => {
          resolve(res);
          // data.value = File.name
        });
      })
    }
    // 图片放大
    const dialogVisible=ref(false)
    let dialogImageUrl=ref('')
    const onpreview = (File)=>{
      dialogVisible.value=true
      dialogImageUrl.value=File.url
      console.log(File)
    }
    // 删除图片
    const beforeremove = (File,fileList)=>{
      console.log(File)
      console.log(fileList)
      styD.value=0
      styA.value=0
    }
    const Remove =(file)=>{
      console.log(file)
    }

 // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()
 
    // 内容 HTML
    const valueHtml = ref('')
 
    // 模拟 ajax 异步获取内容
    onActivated(()=>{
      
    })
    onMounted(() => {
      approve.value=[]
      dact()
    })
 
    const toolbarConfig = {
      toolbarKeys: [
        'bold', // 加粗
        'italic', // 斜体
        'through', // 删除线
        'underline', // 下划线
        'bulletedList', // 无序列表
        'numberedList', // 有序列表
        'color', // 文字颜色
        'fontSize', // 字体大小
        'lineHeight', // 行高
        'uploadImage', // 上传图片
        'delIndent', // 缩进
        'indent', // 增进
        'deleteImage',//删除图片
        'justifyCenter', // 居中对齐
        'justifyJustify', // 两端对齐
        'justifyLeft', // 左对齐
        'justifyRight', // 右对齐
        'undo', // 撤销
        'redo', // 重做
        'clearStyle', // 清除格式
        'uploadVideo',
      ],
      excludeKeys: [
        'insertImage', // 网络图片
        'bgColor', // 背景色
        'blockquote', // 引用
        'codeBlock', // 代码段
        'emotion', // 表情
        'fontFamily', // 字体
        'headerSelect', // 标题 
        'fullScreen', // 全屏
      ],
    }
    // 富文本上传图片地址
    // let fils=ref('')
    // placeholder: '请输入内容...',
    const editorConfig = { MENU_CONF: {} }
      // 视频上传
      editorConfig.MENU_CONF['uploadVideo'] = {
      // 上传视频的配置
      // 接口
      server:'/admin/child/saveEditorImage',
      // 请求头
      headers: {
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
      maxFileSize: 40 * 1024 * 1024, // 1M
      //上传字段 
      fieldName: 'imageFile',
      maxNumberOfFiles: 1,
      // 上传前回调
      onBeforeUpload(file) {
        console.log(file)
        let fileObj = Object.values(file)[0].data
        console.log(fileObj)
        // 压缩图片
        let isLt2M = Object.values(file)[0].data.size
        if(isLt2M<41943040){
          return true
        }else if(isLt2M>41943040){
          ElMessage({
            message: '视频大小不能超过40MB,请重新上传',
            type: "warning",
            center: true,
          });
          return false
        }
      },
      // 上传成功后回调
      onSuccess(file, res) {
        console.log(file)
        console.log(res)
      },
      // 上传失败回调
      onError() {
        ElMessage({
            message: '视频大小不能超过40MB,请重新上传',
            type: "warning",
            center: true,
          });
      },
    }


    // 图片上传
    editorConfig.MENU_CONF['uploadImage'] = {
    // 上传图片的配置 
    // 接口
      server:'/admin/child/saveEditorImage',
    // 请求头
      headers: {
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
      maxFileSize: 1 * 1024 * 1024, // 1M
    //上传字段 
      fieldName: 'imageFile',
      // meta: {
      //   imageFile:fils
      // },
      // 最多可上传几个文件，默认为 100
      maxNumberOfFiles: 1,
      // 上传前回调
      onBeforeUpload(file) {
        console.log(file)
        let fileObj = Object.values(file)[0].data
        console.log(fileObj)
        // 压缩图片
        let isLt2M = Object.values(file)[0].data.size
        if(isLt2M<1048576){
          return true
        }else if(isLt2M>1048576){
          ElMessage({
            message: '图片大小不能超过1MB,请重新上传',
            type: "warning",
            center: true,
          });
          return false
        }
      },
      // 上传成功后回调
      onSuccess(file, res) {
        console.log(file)
        console.log(res)
      },
      // 上传失败回调
      onError() {
        ElMessage({
            message: '图片大小不能超过1MB,请重新上传',
            type: "warning",
            center: true,
          });
      },
    }
 
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value 
      if (editor == null) return
      editor.destroy()
      console.log('页面销毁了')
    })
 
    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
      // console.log(editor)
    }
    // 标题验证
    const ruleFormRef = ref(null)
    const form = reactive({
      name:"",
      sex:"",
      breviary:"",
      copyreader:"",
      are:'',
      bywork:'',
      classify:''
    })
    // 验证表单提示
    const rules ={
      are:[
        { required: true, message: "请选择是否发布", trigger: "change" },
      ],
      copyreader:[
        { required: true, message: "请填写内容", trigger: "change" },
      ],
      name:[
        { required: true, message: "请填写标题", trigger: "blur" },
      ],
      sex:[
        { required: true, message: "请选择分类", trigger: "change" },
      ],
      classify:[
        { required: true, message: "请选择类型", trigger: "change" },
      ],
    }
    return{
      loading,
      imgss,
      approve,
      header,
      succ,
      data,
      // 按钮加载
      ConfirmLoading,
      // 上传图片
      httpreqst,
      dialogVisible,
      dialogImageUrl,
      onpreview,
      beforeremove,
      Remove,
      styA,
      styD,
      change,
      beforeImageUpload,
      // handlePictureCardPreview,
      // 编辑器
      editorRef,
      valueHtml,
      mode: 'default',
      toolbarConfig,
      editorConfig,
      handleCreated,
      // 标题验证
      ruleFormRef,
      form,
      rules
    }
  }
 }
</script>
<style lang='scss' scoped>
::v-deep .cat{
  width: 97%;
  // margin-left: 100px;
  border: 1px solid #DCDFE6;
  padding: 10px;
  margin-left: 10px;
  overflow: hidden;
}
::v-deep .bianji{
  display: flex;
  flex-direction: column;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
::v-deep .chart-card{
  height: calc(100vh - 145px);
  overflow: auto;
}
::v-deep .subject{
  margin-left: 3px;
  font-size: var(--el-form-label-font-size);
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--el-text-color-regular);
  span{
    color: var(--el-color-danger);
  }
}
// .w-e-text-container [data-slate-editor] p{
//   margin-top: 22px;
//   margin-bottom: 22px;
// }
::v-deep .el-button{
  width: 100px;
  height: 40px;
  font-size: 16px;
}
::v-deep .el-form-item__content{
  align-items: flex-end;
}
::v-deep .referrals{
  color: #cccccc;
  margin-left: 5px;
}
::v-deep .styB .el-upload--picture-card{
  width:110px;
  height:110px;
  line-height:110px;
}
::v-deep .styC .el-upload--picture-card{
  display:none;   
}
::v-deep .el-form-item{
  align-items: center;
}
::v-deep .aaaa{
  width: 50%;
}
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-main{
  overflow: auto !important;
}
::v-deep .el-card.is-always-shadow{
  width: 95%;
  margin: auto;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  overflow: auto !important;
}
</style>
<style scoped>
::v-deep .upload-demo{
  display: flex;
  width: 100%;
}
::v-deep .upload-demo .el-upload-list{
  display: flex;
  width: 50%;
}
::v-deep .el-input{
  height: 44px !important;
}
::v-deep .el-form-item__label{
  width: 100px !important;
  font-weight: bold;
}
</style>
